@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Century Gothic';
  font-display: swap;
  src: url('/fonts/century-gothic.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Century Gothic';
  font-display: swap;
  src: url('/fonts/century-gothic-bold.woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Crusellia';
  font-display: swap;
  src: url('/fonts/crusellia.woff2');
  font-weight: 400;
}

@layer base {
  html,
  body {
    @apply max-w-full font-body text-black-knight selection:bg-young-fern selection:text-black-knight lg:scroll-pt-28;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button {
    @apply border-none bg-transparent;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }

  p {
    @apply leading-[25px] mb-4;
  }

  p:last-child {
    @apply mb-0;
  }

  em {
    @apply font-display;
  }
}

@layer components {
  .btn {
    @apply rounded-full py-3 px-5 inline-block border-[1px] border-transparent;
  }

  .btn-light {
    @apply bg-transparent border-white text-white hover:bg-peach-fury hover:border-peach-fury transition-colors;
  }

  .btn-black-knight {
    @apply border-black-knight text-black-knight hover:bg-peach-fury hover:border-peach-fury transition-colors;
  }

  .btn-flush-orange {
    @apply bg-flush-orange text-white hover:bg-peach-fury transition-colors;
  }
}

@layer utilities {
  .ellipsis {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
  }

  .container-xl {
    @apply xl:max-w-[1390px] mx-auto px-4 lg:px-8;
  }

  .content-checkmark ul {
    @apply !list-none;
  }

  .content-checkmark ul > li {
    @apply relative py-3 before:absolute before:bg-[url('/img/checkmark.svg')] before:bg-no-repeat before:w-[24px] before:h-[17px] before:-left-[35px] before:top-[14px];
  }

  .content-xxxl {
    @apply children-h2:text-5xl lg:children-h2:text-9xl;
  }

  .content-xxl {
    @apply children-h1:text-4xl lg:children-h1:text-6xl children-h2:text-3xl sm:children-h2:text-5xl lg:children-h2:text-6xl sm:children-p:pr-24 children-p:py-4;
  }

  .content-xl {
    @apply children-h1:text-4xl lg:children-h1:text-5xl children-h2:text-4xl lg:children-h2:text-5xl;
  }

  .content-lg {
    @apply children-h1:text-2xl sm:children-h1:text-3xl lg:children-h1:text-4xl children-h2:text-2xl sm:children-h2:text-3xl lg:children-h2:text-4xl children-h2:mb-4 lg:children-h2:mb-6 children-blockquote:text-flush-orange lg:children-blockquote:text-xl lg:children-blockquote:mb-6;
  }

  .content-md {
    @apply children-h3:text-xl children-h3:mb-4 lg:children-h3:text-2xl lg:children-h3:mb-6;
  }

  .bg-glass {
    @apply backdrop-blur-[9.5px] shadow-sm bg-black-knight bg-opacity-80;
  }
}

/* ----------START PARSECONTENT---------- */
.parsecontent h1 {
  @apply mb-5;
}

.parsecontent strong,
.parsecontent b,
.parsecontent h1 strong,
.parsecontent h1 b,
.parsecontent h2 strong,
.parsecontent h2 b,
.parsecontent h3 strong,
.parsecontent h3 b,
.parsecontent h4 strong,
.parsecontent h4 b,
.parsecontent h5 strong,
.parsecontent h5 b {
  @apply font-bold;
}

.parsecontent h1:last-child,
.parsecontent h2:last-child,
.parsecontent h3:last-child,
.parsecontent h4:last-child,
.parsecontent h5:last-child,
.parsecontent p:last-child {
  @apply mb-0;
}

.parsecontent a {
  @apply font-bold;
}

.parsecontent a:hover {
  @apply underline;
}

.parsecontent ul {
  @apply list-disc pl-10;
}

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}
/* ----------END PARSECONTENT---------- */

/* ----------START FORM---------- */
.form-duuf-group {
  @apply mb-5;
}

.form-duuf-input,
.form-duuf-textarea {
  @apply lg:min-w-[345px] px-5 h-12 w-full border-none bg-slate-100 text-slate-800 !shadow-none !ring-0 placeholder:text-slate-500;
}

.form-duuf-textarea {
  @apply pt-3 min-h-[130px];
}

.form-duuf-file-input {
  @apply text-slate-500 file:mr-4 file:rounded-full file:border-none file:bg-slate-800 file:py-2 file:px-4 file:text-white file:hover:bg-blue-500 file:hover:transition-all;
}

.form-duuf-checkbox {
  @apply mr-4 h-7 w-7 rounded-md border-none bg-slate-100 text-slate-500 focus:border-none focus:outline-none active:border-none active:outline-none;
}

.form-duuf-select__control {
  @apply h-12 !border-none !bg-slate-100 !shadow-none;
}

.form-duuf-select__menu {
  @apply rounded-lg;
}

.form-duuf-select__menu-list {
  @apply px-3 py-0 text-black;
}

.form-duuf-select__option {
  @apply my-1 cursor-pointer rounded-lg transition-all;
}

.form-duuf-select__option:first-of-type {
  @apply mt-3;
}

.form-duuf-select__option:last-of-type {
  @apply mb-3;
}

.form-duuf-select__option:active {
  @apply !bg-slate-500 !text-white;
}

.form-duuf-select__option--is-focused {
  @apply !bg-slate-100;
}

.form-duuf-select__option--is-selected {
  @apply !bg-slate-800;
}

.form-duuf-select__input {
  @apply !border-none !shadow-none !outline-none;
}

.form-duuf-select__single-value {
  @apply !top-[30px] !left-[12px] !m-0 !text-slate-800;
}

.form-duuf-select__placeholder {
  @apply !top-[24px] !left-[12px] !m-0 !text-slate-500;
}

.form-duuf-group {
  @apply relative;
}

.form-duuf-error {
  @apply text-sm text-red-500;
}

.form-duuf-label {
  @apply px-2;
}

.form-duuf-submit-disabled {
  @apply opacity-50;
}

.form-duuf-submit > button {
  @apply btn btn-flush-orange;
}

.form-duuf-select__value-container {
  @apply !px-6;
}

.form-duuf-loading {
  @apply absolute left-0 top-0 z-10 h-full w-full bg-white bg-opacity-50;
}
/* ----------END FORM---------- */
/* ----------START SWIPER OVERRIDES---------- */

.swiper-pagination-bullet {
  @apply !bg-silver-polish;
}

.swiper-pagination-bullet-active {
  @apply !bg-white;
}

/* ----------END SWIPER OVERRIDES---------- */

.ReactModal__Overlay {
  @apply z-[999];
}
